( function($) {

  "use strict";

  let $form_validation = hyperform(
    document.forms[ 'newsletter_subscription' ],
    { revalidate: 'onsubmit', classes: { warning: 'invalid_input', valid: 'valid_input' } }
  );

} (jQuery) );
